<script lang="ts">
  import type { EpisodesEntity } from "./@types/shows";
  import EpisodeNumberOrPublished from "./EpisodeNumberOrPublished.svelte";
  import { shows } from "./shows";
  export let episode: EpisodesEntity;
  const backupImageForError = (fallbackImages: string[]) => {
    let targetIndex = 0;
    return (e) => {
      if (targetIndex > fallbackImages.length - 1) return;
      e.srcElement.src = fallbackImages[targetIndex];
      targetIndex++;
    };
  };
  const show = shows.find((show) => {
    return show.key === episode.showKey;
  });
  const imageErrorHandler = backupImageForError([show.image]);
  let episodePageUrl = (episode) => {
    const hasMp3 = episode.link.match(/\.mp3/);
    return hasMp3 ? show.showPage : episode.link;
  };
</script>

<div class="episode">
  <img
    class="episodeImage"
    height={120}
    width={120}
    src={episode.itunes_image}
    alt={episode.title}
    on:error={imageErrorHandler}
  />
  <h3>
    <img src={show.image} class="showArt" width="40" alt={show.title} />
    <a href={episodePageUrl(episode)}> {episode.title}</a>
  </h3>

  <p>{episode.itunes_summary || episode.itunes_subtitle}</p>
  <EpisodeNumberOrPublished {episode} />
</div>

<style>
  img.episodeImage {
    height: 120px;
    width: 120px;
    float: right;
    margin-left: 1em;
    margin-bottom: 0.5em;
  }
  .episode {
    padding-bottom: 0.5em;
    clear: both;
    max-width: 50em;
    min-height: 120px;
    border-bottom: 1px solid cadetblue;
  }
  .showArt {
    vertical-align: middle;
  }
</style>
