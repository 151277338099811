module.exports = {
  shows: [
    {
      key: "gs",
      prefixText: "Humanity and Technology.",
      note: "From the fringes of Silicon Valley.",
      title: "Geek Speak with Lyle Troxell",
      image: "/images/podcasts/GeekSpeak_800px.jpg",
      showPage: "https://geekspeak.org/",
      rss: "https://geekspeak.org/episodes/rss.xml",
      color: "#1f86de38;",
      recent: true,
    },
    {
      key: "it",
      prefixText: "One Important Thing, with Michael Lopp.",
      note: "Sometimes there are two.",
      title: "The Important Thing",
      image: "/images/podcasts/The_Important_Thing_800px.jpg",
      showPage: "https://randsinrepose.com/the-important-thing/",
      rss: "https://rands.libsyn.com/feed",
      color: "#cc3c051c;",
      recent: true,
    },
    {
      key: "wan",
      prefixText: "Netflix employees talking about life and work.",
      note: "Netflix culture",
      title: "WeAreNetflix",
      image: "/images/podcasts/WeAreNetflix_800px.jpg",
      showPage: "https://jobs.netflix.com/podcast",
      rss: "https://feeds.simplecast.com/QAN5l_z5",
      color: "#80808036;",
      recent: true,
    },
    {
      key: "lwl",
      prefixText: "Being a Healthy Human",
      note: "Fun-A-Day for January 2022",
      title: "Lunch with Lyle",
      image: "/images/podcasts/lunch-with-lyle-logo_800x800.jpg",
      showPage: "https://lwl.troxell.com",
      rss: "https://feeds.simplecast.com/jtpcol_C",
      color: "#82b77f62;",
    },
    {
      key: "aoc",
      prefixText: "Interviews of Artists from UCSC.",
      note: "Art changes the world.",
      title: "The Art of Change",
      image: "/images/podcasts/Art_of_Change_UCSC_800px.jpg",
      showPage: "https://the-art-of-change.simplecast.com/",
      rss: "https://feeds.simplecast.com/MEQpJA7_",
      color: "#ffbc003d;",
    },
    {
      key: "mso",
      prefixText: "Experts in New Media.",
      note: "Seven Episodes Released in 2008.",
      title: "Media Sound Off",
      image: "/images/podcasts/MediaSoundOff_800px.jpg",
      showPage: "https://mediasoundoff.com/",
      rss: "https://mediasoundoff.com/podcast/feed",
      color: "#ff00003d;",
    },
  ],
};
