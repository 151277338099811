<script lang="ts">
  import type { ShowPayload, EpisodesEntity, ShowStyle } from "./@types/shows";
  import Episode from "./Episode.svelte";
  import { shows } from "./shows";
  import ShowCard from "./ShowCard.svelte";
  let baseUrl = "APIURL";
  let amountOfEpisodesToDisplay = 30;
  async function fetchData(): Promise<ShowPayload> {
    const response = await fetch(
      `${baseUrl}/api/recent/${amountOfEpisodesToDisplay}`
    );
    return await response.json();
  }
</script>

{#await fetchData()}
  <div id="podcasts" class="podcasts">
    {#each shows.filter((s) => s.recent) as show}<ShowCard {show} />{/each}
  </div>
  <p>...waiting</p>
{:then data}
  <div class="activePodcasts">
    <div id="podcasts" class="podcasts">
      {#each data.shows.filter((s) => s.recent) as show}<ShowCard
          {show}
        />{/each}
    </div>
    <div>
      <h2>
        The {amountOfEpisodesToDisplay.toLocaleString()} most recent episodes:
      </h2>
      {#each data.episodes as episode}
        <Episode {episode} />
        <!-- <div>{episode.title}</div> -->
      {/each}
    </div>
  </div>
  <div id="inactivePodcasts">
    <h3>Inactive Podcasts</h3>
    <div class="podcasts">
      {#each data.shows.filter((s) => !s.recent) as show}<ShowCard
          {show}
        />{/each}
    </div>
  </div>
{:catch error}
  <p>An error occurred! {error}</p>
{/await}

<style>
  h2 {
    font-weight: 100;
  }
</style>
