<script lang="ts">
  import type { EpisodesEntity } from "./@types/shows";
  export let episode: EpisodesEntity;
</script>

<div class="episodeNumberOrPublishDate">
  {#if episode.itunes_episode && episode.itunes_season}
    <b>Season {episode.itunes_season}, Episode {episode.itunes_episode}</b>
  {:else}
    <b>published {new Date(episode.published).toLocaleDateString()}</b>
  {/if}
</div>
