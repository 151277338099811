<script lang="ts">
  import type { Show, ShowStyle } from "./@types/shows";
  export let show: Show;
  $: backgroundColor = show.color;
  $: border = "1px solid {showStyle.color}";
  const style = `background-color:${show.color};border:1px solid ${show.color};`;
  const recentClass = show.recent ? "recent" : "inactive";
</script>

<div class="item {recentClass}" {style}>
  <p>{show.prefixText}</p>
  <a href={show.showPage}><img alt={show.title} src={show.image} /></a>
  <div class="note">{show.note}</div>
</div>

<!-- <style>
  .item {
    
  }
</style>  -->
